import React from 'react';
import { Box, Link } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

function HeaderMenu() {
  const linkStyles = {
    fontFamily: "'Inter', sans-serif", // Font family
    fontStyle: 'normal', // Font style
    fontWeight: 300, // Lighter font weight
    fontSize: '16px', // Font size
    lineHeight: '17px', // Line height
    textDecorationColor: 'black', // Underline color always black
    color: 'rgba(0, 0, 0, 0.9)', // Slightly transparent black text
    '&:hover': {
      textDecorationColor: 'black', // Ensure underline stays black on hover
    },
    '&:focus': {
      textDecorationColor: 'black', // Ensure underline stays black on focus
    },
  };

  return (
    <Box
      component="nav" // Change footer to nav since it's a menu
      sx={{
        height: '20px', // Thinner height for the menu
        bgcolor: 'white', // White background
        display: 'flex',
        justifyContent: 'center', // Center the links horizontally
        alignItems: 'center', // Center the links vertically
        mt: 2,
      }}
    >
      {/* Links Container */}
      <Box
        sx={{
          display: 'flex',
          gap: 1.7, // Space between the links (16px)
        }}
      >
        {/* Use Material-UI Link + React Router Link */}
        <Link
          component={RouterLink} // Use React Router's Link for routing
          to="/" // React Router's "to" for navigation
          underline="always"
          sx={linkStyles}
        >
          home
        </Link>
        <Link
          component={RouterLink}
          to="/docs"
          underline="always"
          sx={linkStyles}
        >
          docs
        </Link>
        <Link
          component={RouterLink}
          to="/about"
          underline="always"
          sx={linkStyles}
        >
          about us
        </Link>
      </Box>
    </Box>
  );
}

export default HeaderMenu;
