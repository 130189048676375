import React from 'react';
import { Box, Typography } from '@mui/material';

function MyHeader() {
  return (
    <Box
      component="header"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '10vh', // Dynamic height: 10% of the viewport height
        alignItems: 'center',
        justifyContent: 'center',
        mt:2,
      }}
    >
      {/* Slogan Row */}
      <Box
        sx={{
          flex: 1, // Take proportional space
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          fontSize: '14px',
        }}
      >
        <Typography
          variant="caption"
          sx={{
            fontFamily: "'Inter', sans-serif",
            fontWeight: 300, // Light font weight for the slogan
            fontSize: '14px',
          }}
        >
          add tasks, finish tasks
        </Typography>
      </Box>

      {/* Header Row */}
      <Box
        sx={{
          flex: 2, // Take proportional space
          display: 'flex',
          alignItems: 'center',
          px: 2,
        }}
      >
        <Typography
          variant="h4"
          component="div"
          sx={{
            fontFamily: "'Inter', sans-serif",
            fontWeight: 700, // Light font weight for the base text
            fontSize: '28px',
          }}
        >
        5Tasks
        </Typography>
      </Box>
    </Box>
  );
}

export default MyHeader;
