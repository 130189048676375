import React, { useState } from 'react';
import { Box, IconButton, Snackbar } from '@mui/material';
import XIcon from '@mui/icons-material/X';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import RedditIcon from '@mui/icons-material/Reddit';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const SocialShare = () => {
  const [open, setOpen] = useState(false);
  const shareUrl = 'https://5tasks.cloud';
  const shareText = "I'm using #5Tasks to get things done -- simple, private, and effective. Try it out!";

  const handleShare = (platform) => {
    const urls = {
      twitter: `https://twitter.com/intent/tweet?text=${encodeURIComponent(shareText)}&url=${encodeURIComponent(shareUrl)}`,
      linkedin: `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(shareUrl)}`,
      reddit: `https://reddit.com/submit?url=${encodeURIComponent(shareUrl)}&title=${encodeURIComponent(shareText)}`
    };
    window.open(urls[platform], '_blank', 'width=600,height=400');
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(`${shareText} ${shareUrl}`);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box 
      sx={{ 
        position: 'fixed',
        bottom: '20px',
        left: '50%',
        transform: 'translateX(-50%)',
        backgroundColor: 'white',
        padding: '2px',
        boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
        zIndex: 1000,
        display: 'flex',
        gap: '2px'
      }}
    >
      <IconButton onClick={() => handleShare('twitter')} size="small">
        <XIcon />
      </IconButton>
      <IconButton onClick={() => handleShare('linkedin')} size="small">
        <LinkedInIcon />
      </IconButton>
      <IconButton onClick={() => handleShare('reddit')} size="small">
        <RedditIcon />
      </IconButton>

      <IconButton onClick={handleCopy} size="small" sx={{ padding: '4px' }}>
        <ContentCopyIcon fontSize="small" />
      </IconButton>
      
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        message="Share message copied to clipboard!"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        ContentProps={{
          sx: {
            fontSize: '0.8rem',
            padding: '6px',
            minWidth: '250px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }
        }}
      />
    </Box>
  );
};

export default SocialShare;
