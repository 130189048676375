import React from 'react';
import { Box, Typography, Container, Paper } from '@mui/material';
import SocialShare from '../components/SocialShare';

function Docs() {
    return (
        <Container maxWidth="md" sx={{ py: 4 }}>
            <Paper elevation={0} sx={{ p: 4, bgcolor: '#F3EFEE' }}>
                
                <Box sx={{ mb: 4 }}>
                    <Typography variant="body1" sx={{ 
                        mb: 2,
                        color: 'rgba(0, 0, 0, 0.8)',
                        fontFamily: "'Inter', sans-serif" 
                    }}>
                        5Tasks is a minimal and easy-to-use app designed to help you focus on your most important daily tasks.
                    </Typography>
                </Box>
                
                <Box sx={{ mb: 4 }}>
                    <Typography variant="h6" gutterBottom sx={{ 
                        fontFamily: "'Inter', sans-serif",
                        fontWeight: 500 
                    }}>
                        Why Choose 5Tasks?
                    </Typography>
                    <Box component="ul" sx={{ 
                        pl: 2,
                        '& li': { 
                            mb: 1,
                            color: 'rgba(0, 0, 0, 0.8)',
                            fontFamily: "'Inter', sans-serif"
                        } 
                    }}>
                        <li>Works offline: No need to sign up or log in.</li>
                        <li>Privacy-first: Your data stays on your device.</li>
                    </Box>
                </Box>
                
                <Box sx={{ mb: 4 }}>
                    <Typography variant="h6" gutterBottom sx={{ 
                        fontFamily: "'Inter', sans-serif",
                        fontWeight: 500 
                    }}>
                        How to Use
                    </Typography>
                    <Box component="ol" sx={{ 
                        pl: 2,
                        '& li': { 
                            mb: 1,
                            color: 'rgba(0, 0, 0, 0.8)',
                            fontFamily: "'Inter', sans-serif"
                        } 
                    }}>
                        <li>Write your task description and hit Enter.</li>
                        <li>Check to mark tasks as complete.</li>
                        <li>Click 'X' to delete tasks.</li>
                    </Box>
                </Box>

                <Box>
                    <Typography variant="h6" gutterBottom sx={{ 
                        fontFamily: "'Inter', sans-serif",
                        fontWeight: 500 
                    }}>
                        Tips for Success
                    </Typography>
                    <Box component="ul" sx={{ 
                        pl: 2,
                        '& li': { 
                            mb: 1,
                            color: 'rgba(0, 0, 0, 0.8)',
                            fontFamily: "'Inter', sans-serif"
                        } 
                    }}>
                        <li>Prioritize your most important tasks.</li>
                        <li>You can add as many tasks as you need—we don't limit your experience. However, we chose the name '5Tasks' to remind you that focusing on just five tasks per day is often enough for meaningful progress. Remember: "In order to do more, do less."</li>
                        <li>Review and reset your task list daily for maximum productivity.</li>
                    </Box>
                </Box>
            </Paper>
            <SocialShare />
        </Container>
    );
}

export default Docs;