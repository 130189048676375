import React from "react";
import { Box, Typography } from "@mui/material";
import { ClearTwoTone, CheckBoxTwoTone, CheckBoxOutlineBlankTwoTone } from "@mui/icons-material";

function Task({ task, onEditTask, onDeleteTask }) {

  const completeTask = () => {
    onEditTask(task.id, { ...task, completed: !task.completed });
  }

  const deleteTask = () => {
    onDeleteTask(task.id);
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 1,
        bgcolor: "#F3EFEE",
        border: "1px solid #E0E0E0",
        padding: 1,
        borderRadius: "0px",
        mb: 1,
        mt: 1,
        minHeight: '100px',
        justifyContent: 'space-between'
      }}
    >
      {/* Top section with task description and duration */}
      <Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            opacity: task.completed ? 0.2 : 1,
          }}
        >
          {/* Left Column: Task Description */}
          <Typography
            variant="h5"
            sx={{
              fontSize: "22px",
              fontWeight: 400,
              alignSelf: "flex-start",
              textDecoration: task.completed ? "line-through" : "none",
              fontFamily: "'Inter', sans-serif",
              color: "rgba(0, 0, 0, 0.9)", // Darker text for the description
              flex: 0.99, // Take available space on the left
            }}
          >
            {task.description}
          </Typography>

          {/* Right Column: Estimated Duration */}
         
        </Box>
      </Box>

      {/* Bottom row with date and icons */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "auto"
        }}
      >
        {/* Left Column: Estimated Date */}
        <Box
          sx={{
            display: "flex",
            alignSelf: "flex-end",
            alignItems: "center",
            color: "rgba(0, 0, 0, 0.6)", // Lighter gray text
            opacity: task.completed ? 0.2 : 1,
          }}
        >

          <Typography variant="body2" >
            {task.estimated_date}</Typography>
        </Box>

        {/* Right Column: function Icon */}
        <Box sx={{
          display: "flex",
          alignSelf: "flex-end",
          alignItems: "center",
          color: "rgba(0, 0, 0, 0.6)", // Lighter gray text
        }}>

          {task.completed && (
            <ClearTwoTone
              onClick={deleteTask}
              sx={{
                cursor: 'pointer',
                fontSize: '28px',
                marginRight: '4px',
                color: "black"
              }}
            />
          )}

          {task.completed ? (
            <CheckBoxTwoTone
              onClick={completeTask}
              sx={{
                cursor: 'pointer',
                fontSize: '28px'
              }}
            />
          ) : (
            <CheckBoxOutlineBlankTwoTone
              onClick={completeTask}
              sx={{
                cursor: 'pointer',
                fontSize: '28px'
              }}
            />
          )}

        </Box>
      </Box>
    </Box>
  );
}

export default Task;
