const TASKS_CACHE_KEY = '5tasks_cache';

export const taskCacheService = {
  // Save tasks to cache
  saveTasks: (tasks) => {
    try {
      localStorage.setItem(TASKS_CACHE_KEY, JSON.stringify(tasks));
      return true;
    } catch (error) {
      console.error('Error saving tasks to cache:', error);
      return false;
    }
  },

  // Get tasks from cache
  getTasks: () => {
    try {
      const cachedTasks = localStorage.getItem(TASKS_CACHE_KEY);
      return cachedTasks ? JSON.parse(cachedTasks) : [];
    } catch (error) {
      console.error('Error reading tasks from cache:', error);
      return [];
    }
  },

  // Clear tasks from cache
  clearTasks: () => {
    try {
      localStorage.removeItem(TASKS_CACHE_KEY);
      return true;
    } catch (error) {
      console.error('Error clearing tasks from cache:', error);
      return false;
    }
  }
};
