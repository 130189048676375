import React from "react";
import Task from "./Task";
import { Box, Typography } from "@mui/material";

function TaskList({ tasks, onEditTask, onDeleteTask }) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column", // Stack tasks vertically
        marginTop: 10, // Add top margin for layout
        mb:8,
      }}
    >
      {tasks.length > 0 ? (
        tasks.map((task) => <Task key={task.id} task={task} onEditTask={onEditTask} onDeleteTask={onDeleteTask} />)
      ) : (
        <Typography variant="body1" color="textSecondary" align="center">
          Let's add some tasks!
        </Typography>
      )}
    </Box>
  );
}

export default TaskList;
