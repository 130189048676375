import { useState, useEffect } from "react";
import TaskForm from "../components/TaskForm";
import TaskList from "../components/TaskList";
import { taskCacheService } from "../services/taskCacheService";

function Home()
{
    const [tasks, setTasks] = useState(taskCacheService.getTasks());
    
    useEffect(() => {
        taskCacheService.saveTasks(tasks);
    }, [tasks]);
    
    const handleAddTask = (task) => {
        setTasks([task,...tasks]);
    };
    
    const handleEditTask = (taskId, updatedTask) => {
        console.log("Editing task with ID:", taskId);
        console.log("Updated Task:", updatedTask);
        const updatedTasks = tasks.map((task) => {
            if (task.id === taskId) {
                return { ...task, ...updatedTask };
            }
            return task;
        });
        setTasks(updatedTasks);
    };

    const handleDeleteTask = (taskId) => {
        console.log("Deleting task with ID:", taskId);
        const updatedTasks = tasks.filter((task) => task.id !== taskId);
        setTasks(updatedTasks);
    };

    return (
        <>
        <TaskForm onAddTask={handleAddTask} />
        <TaskList tasks={tasks} onEditTask={handleEditTask} onDeleteTask={handleDeleteTask} />
        </>
    );
}
export default Home