import React from 'react';
import MyHeader from './components/MyHeader';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import AboutUs from './pages/AboutUs';
import Docs from './pages/Docs';
import { ThemeProvider, createTheme, CssBaseline, Box, Container } from '@mui/material';
import '@fontsource/inter/300.css';
import '@fontsource/inter/400.css';
import '@fontsource/inter/500.css';
import '@fontsource/inter/600.css';
import '@fontsource/inter/700.css';
import HeaderMenu from './components/HeaderMenu';

const theme = createTheme({
  typography: {
    fontFamily: "'Inter', sans-serif",
  },
  palette: {
    background: {
      default: '#FBF7F6',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        html: {
          height: '100%',
        },
        body: {
          height: '100%',
          margin: 0,
          display: 'flex',
          flexDirection: 'column',
        },
        '#root': {
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        },
        '*': {
          boxSizing: 'border-box',
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          maxWidth: '768px !important',
        },
      },
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Box display="flex" flexDirection="column" height="100vh">
          <Container>
            <Box component="header" sx={{ flexShrink: 0 }}>
              <MyHeader />
              <HeaderMenu />
            </Box>

            <Box
              component="main"
              sx={{
                flexGrow: 1,
                overflowY: 'auto',
                pt: '30px',
              }}
            >
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/about" element={<AboutUs />} />
                <Route path="/docs" element={<Docs />} />
              </Routes>
            </Box>
          </Container>
        </Box>
      </Router>
    </ThemeProvider>
  );
}

export default App;