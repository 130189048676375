import React from 'react';
import { Box, TextField, Typography, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

function TaskForm({ onAddTask }) {
  const [task, setTask] = React.useState('');
  
  const formatDate = (date) => {
    const options = { month: 'short', day: '2-digit', year: 'numeric' };
    return date.toLocaleDateString('en-US', options).replace(/(\d+)(?=,)/, (match) => {
      const day = parseInt(match);
      const suffix = ['th', 'st', 'nd', 'rd'][day % 10 > 3 ? 0 : (day % 100 - day % 10 !== 10) * (day % 10)];
      return day + suffix;
    });
  };
  const estimated_date = formatDate(new Date());
  const estimated_duration = 60;

  const handleSubmit = (e) => {
    e.preventDefault();
    if (task.trim()) {
      onAddTask({ id: Date.now(), description: task, completed: false, estimated_date, estimated_duration, created_at: new Date() });
      setTask('');
    }
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '4px',
        fontFamily: "'Inter', sans-serif",
        mt:9,
      }}
    >
      {/* Row for Date and Minutes */}
      <Box
        sx={{
          display: 'flex',
          gap: 1,
          marginBottom: '-4px',
          fontFamily: "'Inter', sans-serif",
        }}
      >
        <Typography
          variant="body2"
          sx={{
            textDecoration: 'underline',
            cursor: 'pointer',
            fontSize: '15px',
            color: 'rgba(0, 0, 0, 0.7)',
            fontFamily: "'Inter', sans-serif'",
          }}
        >
            {estimated_date}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            textDecoration: 'underline',
            cursor: 'pointer',
            fontSize: '15px',
            color: 'rgba(0, 0, 0, 0.7)',
            fontFamily: "'Inter', sans-serif",
          }}
        >
         {/* 60mins  */}
        </Typography>
      </Box>

      {/* Row for Input Field with Plus Button */}
      
      <TextField
        value={task}
        onChange={(e) => setTask(e.target.value)}
        placeholder="Add a new task..."
        variant="standard"
        fullWidth
        autoComplete="off"
        sx={{
          bgcolor: '#F3EFEE',
          paddingRight: '4px',
          boxShadow: 'none',
          mt:0.5,
        }}
        slotProps={{
            input: {
                style: {
                    paddingTop: '13px',
                    paddingBottom: '13px',
                    paddingLeft: '6px',
                    fontSize:'25px',
                    fontFamily: "'Inter', sans-serif",
                    border: '0.5px solid lightgray',
                    boxShadow: 'none',
                    '&:hover': {
                    border: '1px solid darkgray',
                    },
                  },
              endAdornment: (
                <>
                  <IconButton 
                    size="small" 
                    type="submit"
                    onClick={handleSubmit}
                  >
                    <AddIcon sx={{fontSize:'35px',}}/>
                  </IconButton>
                </>
                
              ),
            },
          }}
      />
    </Box>
  );
}

export default TaskForm;
