import React from 'react';
import { Box, Typography, Container, Paper, Link } from '@mui/material';
import { Email, LinkedIn, Language, LocationOn } from '@mui/icons-material';
import synth9Logo from '../assets/images/synth9_logo.svg';
import SocialShare from '../components/SocialShare';

function AboutUs() {
    return (
        <Container maxWidth="md" sx={{ py: 4 }}>
            <Paper elevation={0} sx={{ p: 4, bgcolor: '#F3EFEE' }}>
                {/* Logo Section */}
                <Box sx={{ 
                    width: '100%', 
                    display: 'flex', 
                    justifyContent: 'center',
                    mb: 4 
                }}>
                    <Box sx={{
                        width: '110px',
                        height: '110px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        overflow: 'hidden'
                    }}>
                        <img 
                            src={synth9Logo} 
                            alt="Synth9 Logo" 
                            style={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'contain'
                            }}
                        />
                    </Box>
                </Box>

                {/* Company Description */}
                <Box sx={{ mb: 4 }}>
                  
                    
                    <Typography variant="body1" sx={{ 
                        mb: 2,
                        color: 'rgba(0, 0, 0, 0.8)',
                        fontFamily: "'Inter', sans-serif",
                        lineHeight: 1.6,
                        textAlign: 'left'
                    }}>
                        We are Synth9, your trusted partner for designing and building exceptional web applications.
                    </Typography>

                    <Typography variant="body1" sx={{ 
                        mb: 4,
                        color: 'rgba(0, 0, 0, 0.8)',
                        fontFamily: "'Inter', sans-serif",
                        lineHeight: 1.6,
                        textAlign: 'left'
                    }}>
                        We can turn your ideas into scalable solutions, whether you’re starting small or aiming for big, impactful platforms.
                    </Typography>
                </Box>

                {/* Contact Information */}
                <Box sx={{ 
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    gap: 2
                }}>
                    <Typography variant="body1" gutterBottom sx={{ 
                        fontFamily: "'Inter', sans-serif",
                        fontWeight: 500,
                        textAlign: 'left'
                    }}>
                        Got a business idea? Let's make it happen
                    </Typography>

                    <Box sx={{ 
                        display: 'flex', 
                        alignItems: 'center',
                        gap: 1
                    }}>
                        <Email sx={{ color: 'rgba(0, 0, 0, 0.6)' }} />
                        <Link 
                            href="mailto:mamin@synth9.com"
                            sx={{ 
                                color: 'rgba(0, 0, 0, 0.8)',
                                textDecoration: 'none',
                                fontFamily: "'Inter', sans-serif'",
                                '&:hover': {
                                    textDecoration: 'underline'
                                }
                            }}
                        >
                            mamin@synth9.com
                        </Link>
                    </Box>

                   

                    <Box sx={{ 
                        display: 'flex', 
                        alignItems: 'center',
                        gap: 1
                    }}>
                        <Language sx={{ color: 'rgba(0, 0, 0, 0.6)' }} />
                        <Link 
                            href="https://synth9.com"
                            target="_blank"
                            rel="noopener noreferrer"
                            sx={{ 
                                color: 'rgba(0, 0, 0, 0.8)',
                                textDecoration: 'none',
                                fontFamily: "'Inter', sans-serif",
                                '&:hover': {
                                    textDecoration: 'underline'
                                }
                            }}
                        >
                            synth9.com
                        </Link>
                    </Box>
                    <Box sx={{ 
                        display: 'flex', 
                        alignItems: 'center',
                        gap: 1
                    }}>
                        <LinkedIn sx={{ color: 'rgba(0, 0, 0, 0.6)' }} />
                        <Link 
                            href="https://www.linkedin.com/company/synth9/"
                            target="_blank"
                            rel="noopener noreferrer"
                            sx={{ 
                                color: 'rgba(0, 0, 0, 0.8)',
                                textDecoration: 'none',
                                fontFamily: "'Inter', sans-serif",
                                '&:hover': {
                                    textDecoration: 'underline'
                                }
                            }}
                        >
                            Synth9
                        </Link>
                    </Box>
                    <Box sx={{ 
                        display: 'flex', 
                        alignItems: 'center',
                        gap: 1
                    }}>
                        <LocationOn sx={{ color: 'rgba(0, 0, 0, 0.6)' }} />
                        <Typography
                            sx={{ 
                                color: 'rgba(0, 0, 0, 0.8)',
                                fontFamily: "'Inter', sans-serif",
                            }}
                        >
                            Dubai - UAE
                        </Typography>
                    </Box>

                    
                </Box>
            </Paper>
            <SocialShare />
        </Container>
    );
}

export default AboutUs;
